.AwesomeListMenu {
  position: sticky;
  top: 0px;
}

@media (max-width: 768px) {
  .AwesomeListMenu {
    display: none;
  }
}
