.AwesomeInput {
  position: relative;
  z-index: 100;
  display: inline-block;
}

.SearchResult {
  position: absolute;
  left: 0px;
  width: 360px;
  background-color: white;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
  padding: 3px;
  box-sizing: border-box;
  overflow: hidden;
}

@media (max-width: 768px) {
  .AwesomeInput {
    width: 100%;
  }

  .SearchResult {
    width: 100%;
  }
}

.HomeIcon {
  margin-right: 2px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.HomeIcon:hover {
  color: red;
}
